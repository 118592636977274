/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-sql': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM0 14.841a1.13 1.13 0 00.401.823q.194.162.478.252c.284.09.411.091.665.091q.507 0 .858-.158.355-.159.54-.44a1.17 1.17 0 00.187-.656q0-.336-.135-.56a1 1 0 00-.375-.357 2 2 0 00-.565-.21l-.621-.144a1 1 0 01-.405-.176.37.37 0 01-.143-.299q0-.234.184-.384.187-.152.513-.152.214 0 .37.068a.6.6 0 01.245.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.199-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 01.153.325q0 .165-.085.29A.56.56 0 012 15.31q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 01-.248-.115.58.58 0 01-.255-.384zm6.878 1.489l-.507-.739q.264-.243.401-.6.138-.358.138-.806v-.501q0-.556-.208-.967a1.5 1.5 0 00-.589-.636q-.383-.225-.917-.225-.527 0-.914.225-.384.223-.592.636a2.14 2.14 0 00-.205.967v.5q0 .554.205.965.208.41.592.636a1.8 1.8 0 00.914.222 1.8 1.8 0 00.6-.1l.294.422h.788zM4.262 14.2v-.522q0-.369.114-.63a.9.9 0 01.325-.398.9.9 0 01.495-.138q.288 0 .495.138a.9.9 0 01.325.398q.115.261.115.63v.522q0 .246-.053.445-.053.196-.155.34l-.106-.14-.105-.147h-.733l.451.65a.6.6 0 01-.251.047.87.87 0 01-.487-.147.9.9 0 01-.32-.404 1.7 1.7 0 01-.11-.644m3.986 1.057h1.696v.674H7.457v-3.999h.79z"/>',
    },
});
